<template>
  <!-- F8F9FA -->
  <BasePage :loading="loading">
    <Container v-if="post && !loading">
      <div>
        <div class="text-h5 mb-4" v-text="post[lang + 'title']" />
        <div class="d-flex mt-2" v-if="type == 1">
          <div class="subtitle-2">
            <span v-text="$t('by')" />
            <span
              class="font-weight-bold mr-1"
              v-text="
                post.author_id.first_name + ' ' + post.author_id.last_name
              "
            />
          </div>
          <div class="mx-2" v-text="' | '" />
          <div class="subtitle-2" v-text="post.date" />
        </div>
        <div style="height:650px;width: 100%" class="overflow-hidden">
          <v-img
            :src="post.featured_image.data.full_url"
            style="width:100%;height:100%;objectFit:cover"
          />
        </div>
        <!-- <v-img
          height="500"
          width="100%"
          :src="post.featured_image.data.full_url"
          style="objectFit:cover"
        /> -->
        <div class="my-8" v-html="post[lang + 'body']" />
        <!-- :src="post.video_link" -->
        <template v-if="type == 2">
          <iframe
            v-if="post.video_link"
            width="100%"
            height="600"
            frameborder="0"
            :src="post.video_link"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <v-img
            v-if="post.attachment"
            :src="post.attachment.data.full_url"
            class="mt-5"
          />
          <!-- <base-btn
            v-if="post.attachment"
            :href="post.attachment.data.full_url"
            class=""
            color="primary"
            target="_blank"
            icon
            large
          >
            <v-icon style="fontSize:35px" color="primary"
              >mdi-attachment</v-icon
            >
          </base-btn> -->

          <!-- Instructor -->
          <div v-if="$route.query.tag_id == 1" class="mt-10">
            <h4 class="text-h6 font-weight-bold primary--text mb-5">
              {{ $t('author') }}
            </h4>

            <div class="d-flex flex-row align-center">
              <div>
                <v-img
                  v-if="post.author_id && post.author_id.avatar"
                  style="width: 100%"
                  :src="post.author_id.avatar.data.full_url"
                />
                <v-icon v-else style="fontSize:80px">
                  mdi-account-circle
                </v-icon>
              </div>
              <div>
                <div class="mbb-3">
                  <h3 class="text-h5" v-if="post.author_id">
                    {{
                      post.author_id.first_name + ' ' + post.author_id.last_name
                    }}
                  </h3>
                  <h4 class="subtitle-1">{{ $t('job') }}</h4>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  props: ['id'],
  data() {
    return {
      loading: false,
      post: null,
      type: this.$route.query.type,
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    async getPostDetails() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/posts?fields=*,featured_image.data.full_url,author_id.first_name,author_id.last_name,attachment.data.full_url&filter[id]=${this.id}`
        );
        this.post = data.data[0];
        console.log(data.data[0]);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getPostDetails();
  },
};
</script>

<style></style>
